import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
	Button,
	Heading,
	StatusIndicator,
	Text,
	useToast,
	TextInput,
} from "@withjuly/solis";
import { PlanFeatures } from "./PlanFeatures";
import {
	PLANS,
	PlanInfo,
	StripeSubscriptionStatus,
	SubscriptionPlan,
	getPlanRelation,
} from "@withjuly/fabric";
import { ArrowLeft, Check, Close } from "@withjuly/julycons";
import { cx } from "@withjuly/frontend-common";
import { trpc } from "../Utility/trpc";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "~/utils/stripe";
import Link from "next/link";
import dayjs from "dayjs";
import { PaymentForm } from "./PaymentForm";
import { useApiErrorHandler } from "~/utils/api";
import posthog from "posthog-js";

export interface UpgradeModalProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	feature?:
		| "discover"
		| "template"
		| "also working with"
		| "wishlist"
		| "ai pitch"
		| "contract analysis"
		| "brand book"
		| "automatic pitching";
}

export const UpgradeModal: React.FC<UpgradeModalProps> = ({
	isOpen,
	setIsOpen,
	feature,
}) => {
	const [plan, setPlan] = useState<PlanInfo>();

	let upsellInfo = {
		title: "Choose your plan",
		description: "Upgrade your plan to get the most out of July",
	};
	if (feature === "discover") {
		upsellInfo = {
			title: "5 of 5 pitches sent",
			description:
				"On Side Hustle, you can send up to 5 pitches per month. Upgrade to reach out to more brands.",
		};
	} else if (feature === "also working with") {
		upsellInfo = {
			title: "Previously Worked With requires an upgrade",
			description:
				"On our paid plans you can see exactly who brands have worked with, and the type of content they made.",
		};
	} else if (feature === "template") {
		upsellInfo = {
			title: "That template requires an upgrade",
			description:
				"On our paid plans you can access more templates to speed up your outreach.",
		};
	} else if (feature === "wishlist") {
		upsellInfo = {
			title: "Upgrade to request brands",
			description:
				"On our paid plans you can request any brand. If we don't have it in our database we'll find it for you.",
		};
	} else if (feature === "ai pitch") {
		upsellInfo = {
			title: "Upgrade to pitch with AI",
			description:
				"On our paid plans you can completely automate your outreach with AI.",
		};
	} else if (feature === "contract analysis") {
		upsellInfo = {
			title: "Upgrade to analyze contracts with AI",
			description:
				"On our paid plans you can easily decipher even the most complicated contracts.",
		};
	} else if (feature === "brand book") {
		upsellInfo = {
			title: "Upgrade to pitch from July's Brand Book",
			description:
				"On our paid plans you can easily search for, and pitch to any brand in our database.",
		};
	} else if (feature === "automatic pitching") {
		upsellInfo = {
			title: "Upgrade for Automatic Pitching",
			description:
				"Unlock automatic pitching with 500 pitches per month, AI-powered outreach, and more.",
		};
	}

	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.DialogPortal>
				<Dialog.Overlay />
				<Dialog.Content className="fixed inset-0 z-[1000] flex max-h-screen bg-gray-900 bg-opacity-70 blur-0 backdrop-blur-[64px] focus:outline-none data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in_0.10s_ease-in-out]">
					<Dialog.Close className="absolute right-4 top-4 z-10 rounded lg:right-8 lg:top-8">
						<Close className="h-auto w-4" />
					</Dialog.Close>

					<div className="mx-auto flex h-full w-full flex-col items-center gap-8 overflow-auto px-6 py-8 lg:justify-center lg:gap-16 lg:pt-0">
						{!plan ? (
							<>
								<div className="flex flex-col items-center gap-2 text-center">
									<Heading variant="h2">{upsellInfo.title}</Heading>
									<Text>{upsellInfo.description}</Text>
								</div>

								<div className="flex w-full flex-col justify-center gap-6 lg:flex-row">
									{!feature ? (
										<PlanCard
											plan={PLANS.free}
											setPlan={setPlan}
											onPlanChange={() => setIsOpen(false)}
										/>
									) : null}
									<PlanCard
										plan={PLANS.trending}
										setPlan={setPlan}
										onPlanChange={() => setIsOpen(false)}
									/>
									<PlanCard
										plan={PLANS.paid}
										setPlan={setPlan}
										onPlanChange={() => setIsOpen(false)}
									/>
									<PlanCard
										plan={PLANS.autopilot}
										setPlan={setPlan}
										onPlanChange={() => setIsOpen(false)}
									/>
								</div>

								<Text>
									Not ready to pay yet?{" "}
									<Link href="/referral" className="underline">
										Invite a friend
									</Link>{" "}
									to try Career for free.
								</Text>
							</>
						) : (
							<CardInputModal
								setIsOpen={setIsOpen}
								plan={plan}
								setPlan={setPlan}
								upsellFeature={feature}
							/>
						)}
					</div>
				</Dialog.Content>
			</Dialog.DialogPortal>
		</Dialog.Root>
	);
};

const PlanCard: React.FC<{
	plan: PlanInfo;
	setPlan: (plan: PlanInfo) => void;
	onPlanChange?: () => void;
}> = ({ plan, setPlan, onPlanChange }) => {
	const { toast } = useToast();

	const utils = trpc.useContext();
	const { data: billing } = trpc.billing.get.useQuery();
	const updateSubscription = trpc.billing.updateSubscription.useMutation({
		onSuccess: () => utils.billing.get.invalidate(),
	});

	if (!billing) {
		return null;
	}

	const onClick = () => {
		if (!billing) {
			return;
		}

		const currentPlan = PLANS[billing.plan];
		const relationToCurrentPlan = getPlanRelation(currentPlan, plan);

		if (relationToCurrentPlan === "lower") {
			updateSubscription.mutate(
				{
					plan: plan.plan,
				},
				{
					onSuccess: () => {
						onPlanChange?.();
						toast({
							status: "success",
							title: "Subscription updated",
							description: "Your subscription has been updated.",
						});
					},
				},
			);
		} else {
			setPlan(plan);
		}
	};

	const hasPaymentMethod = !!billing.lastFour;
	const currentPlan = PLANS[billing.plan];
	const relationToCurrentPlan = getPlanRelation(currentPlan, plan);

	let action: "upgrade" | "downgrade" | "current" | "trial" | "contact" =
		"upgrade";
	if (relationToCurrentPlan === "active") {
		if (
			(hasPaymentMethod && !billing.cancelAt) ||
			plan.plan === PLANS.free.plan
		) {
			action = "current";
		} else {
			action = "upgrade";
		}
	} else if (relationToCurrentPlan === "lower") {
		action = "downgrade";
	} else if (relationToCurrentPlan === "higher") {
		action = "upgrade";
	}

	if (plan.plan === "paid" && !billing.hasPreviouslySubscribed) {
		action = "trial";
	} else if (plan.plan === "autopilot") {
		action = "contact";
	}

	let cta = (
		<Button
			variant="primary"
			size="huge"
			onClick={onClick}
			isLoading={updateSubscription.isLoading}
		>
			Upgrade
		</Button>
	);
	if (action === "downgrade") {
		cta = (
			<Button
				variant="secondary"
				size="huge"
				onClick={onClick}
				isLoading={updateSubscription.isLoading}
			>
				Downgrade
			</Button>
		);
	} else if (action === "current") {
		cta = (
			<Button variant="secondary" size="huge" disabled>
				Current plan
			</Button>
		);
	} else if (action === "trial") {
		cta = (
			<Button
				variant="primary"
				size="huge"
				onClick={onClick}
				isLoading={updateSubscription.isLoading}
			>
				Try free for 7 days
			</Button>
		);
	} else if (action === "contact") {
		cta = (
			<Link
				href="mailto:wells@withjuly.com?subject=Autopilot inquiry"
				className="w-full"
			>
				<Button
					variant="secondary"
					size="huge"
					isLoading={updateSubscription.isLoading}
					full
				>
					Get in touch
				</Button>
			</Link>
		);
	}

	const RenewalInfo = () => {
		// Don't show renewal info for free plans
		if (relationToCurrentPlan !== "active" || plan.plan === "free") {
			return null;
		}

		if (
			billing.status === StripeSubscriptionStatus.TRIALING &&
			billing.periodEnds
		) {
			const date = new Date(billing.periodEnds).toLocaleDateString();
			if (hasPaymentMethod) {
				return (
					<Text variant="body/sm" className="text-center text-gray-200">
						Your trial will end on {date}.
					</Text>
				);
			} else {
				return (
					<Text variant="body/sm" className="text-center text-gray-200">
						Your trial will end on {date}. To keep these features, upgrade your
						plan.
					</Text>
				);
			}
		}

		if (billing.cancelAt) {
			const date = new Date(billing.cancelAt).toLocaleDateString();
			return (
				<Text variant="body/sm" className="text-center text-gray-200">
					Your subscription will end on {date}. You won&apos;t be charged after
					that.
				</Text>
			);
		} else if (billing.periodEnds) {
			const date = new Date(billing.periodEnds).toLocaleDateString();
			return (
				<Text variant="body/sm" className="text-center text-gray-200">
					Your subscription will renew on {date}
				</Text>
			);
		}

		return null;
	};

	return (
		<>
			<div
				className={cx(
					"relative flex w-full flex-col gap-8 rounded-xl border bg-gray-800 p-6 lg:max-w-[324px]",
					plan.plan === "paid"
						? "border-blue-500 shadow-[0_0_16px_rgba(124,227,247,0.5)]"
						: "border-gray-500",
				)}
			>
				{plan.plan === "paid" ? (
					<div className="absolute left-0 top-0 -mt-4 flex w-full justify-center">
						<StatusIndicator
							status="brand"
							label="Most popular"
							fill={false}
							className="shadow-[0_0_16px_rgba(124,227,247,0.5)]"
						/>
					</div>
				) : null}

				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-3 text-center">
						<Text variant="bold/sm">{plan.name}</Text>
						<Heading variant="h3">{plan.price}</Heading>
						<Text variant="bold/xs" className="text-gray-200">
							{plan.description}
						</Text>
					</div>

					<div className="h-[1px] w-full bg-gray-500" />
				</div>

				<div className="flex h-full flex-col justify-between gap-6">
					<div className="flex flex-col gap-2">
						<PlanFeatures plan={plan} />
					</div>

					<div className="flex flex-col gap-2">
						<RenewalInfo />
						{cta}
					</div>
				</div>
			</div>
		</>
	);
};

interface CardInputModalProps {
	plan: PlanInfo;
	setIsOpen: (isOpen: boolean) => void;
	setPlan: (plan?: PlanInfo) => void;
	upsellFeature?: string;
}

const CardInputModal: React.FC<CardInputModalProps> = ({
	plan,
	setIsOpen,
	setPlan,
	upsellFeature,
}) => {
	const [isAddingCard, setIsAddingCard] = useState(false);
	const [coupon, setCoupon] = useState("");
	const [isAddCouponOpen, setIsAddCouponOpen] = useState(false);

	const { toast } = useToast();
	const { handleApiError } = useApiErrorHandler();

	const utils = trpc.useContext();
	const updateSubscription = trpc.billing.updateSubscription.useMutation({
		onSuccess: () => utils.billing.get.invalidate(),
	});
	const { data: billing } = trpc.billing.get.useQuery();

	const onCardAdd = async () => {
		updateSubscription.mutate(
			{
				plan: plan.plan,
				isTrial,
				coupon,
			},
			{
				onSuccess: () => {
					utils.billing.get.invalidate();

					setIsOpen(false);
					setPlan(undefined);
					toast({
						status: "success",
						title: "Subscription updated",
						description: "Your subscription has been updated.",
					});

					const relationToCurrentPlan = getPlanRelation(
						PLANS[billing?.plan as SubscriptionPlan],
						plan,
					);
					posthog.capture("Change Plan", {
						plan: plan.plan,
						changeDirection: relationToCurrentPlan,
						isTrial,
						upsellFeature,
					});
				},
				onError: (err) => handleApiError(err),
			},
		);
	};

	const isTrial = plan.plan === "paid" && !billing?.hasPreviouslySubscribed;

	return (
		<div className="flex w-full max-w-[480px] flex-col gap-8">
			<Button
				variant="secondary"
				size="large"
				className="w-fit"
				onClick={() => setPlan(undefined)}
			>
				<div className="flex items-center gap-2">
					<ArrowLeft className="h-auto w-4" /> Back
				</div>
			</Button>

			<div className="flex flex-col gap-6">
				<div className="flex flex-col gap-1">
					<Heading variant="h4">
						{isTrial ? "Start 7-Day Free Trial" : `Upgrade to ${plan.name}`}
					</Heading>
					<Text className="text-gray-200">
						{isTrial
							? `Free for 7 days, then ${plan.price} starting ${dayjs()
									.add(7, "day")
									.format("MMMM D")}`
							: `${plan.price}`}
					</Text>
				</div>

				{isTrial ? (
					<div className="flex flex-col gap-2">
						<div className="flex items-center gap-4">
							<Text className="text-blue-500">
								<Check />
							</Text>
							<Text>You won’t be charged now</Text>
						</div>

						<div className="flex items-center gap-4">
							<Text className="text-blue-500">
								<Check />
							</Text>
							<Text>
								Cancel for free any time before{" "}
								{dayjs().add(7, "day").format("MMMM D")}
							</Text>
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="flex items-center gap-4">
							<Text className="text-blue-500">
								<Check />
							</Text>
							<Text>No lock-in – cancel your plan at any time</Text>
						</div>
					</div>
				)}

				<Elements stripe={getStripe()}>
					<PaymentForm onSuccess={onCardAdd} setIsLoading={setIsAddingCard} />
				</Elements>

				{isAddCouponOpen ? (
					<div className="flex flex-col gap-1">
						<p className="text-xs">Coupon</p>
						<TextInput
							value={coupon}
							onChange={(e) => setCoupon(e.target.value)}
						/>
					</div>
				) : (
					<button
						className="mr-auto text-sm text-gray-200 transition-all hover:text-gray-100"
						onClick={(e) => {
							e.preventDefault();
							setIsAddCouponOpen(true);
						}}
					>
						+ Add Coupon
					</button>
				)}
			</div>

			<div className="flex flex-col gap-6">
				{isTrial ? (
					<div className="flex flex-col gap-2">
						<div className="flex justify-between">
							<Text className="text-gray-200">Due in 7 days</Text>
							<Text>{plan.price}</Text>
						</div>
						<div className="flex justify-between">
							<Text className="text-gray-200">Due now</Text>
							<Text>$0.00</Text>
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="flex justify-between">
							<Text className="text-gray-200">Due now</Text>
							<Text>{plan.price}</Text>
						</div>
					</div>
				)}

				<Button
					size="huge"
					form="payment-form"
					isLoading={updateSubscription.isLoading || isAddingCard}
				>
					{isTrial ? "Start Free Trial" : "Upgrade"}
				</Button>
			</div>
		</div>
	);
};
