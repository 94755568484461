import { PlanInfo } from "@withjuly/fabric";
import { Check, Close } from "@withjuly/julycons";

export interface PlanFeaturesProps {
	negative?: boolean;
	plan: PlanInfo;
}

export const PlanFeatures: React.FC<PlanFeaturesProps> = ({
	negative,
	plan,
}) => {
	let icon = <Check className="text-blue-500" color="blue.500" />;
	if (negative) {
		icon = <Close className="text-danger-500" />;
	}

	return (
		<ul className="flex flex-col gap-2">
			{plan.features.map((feature) => (
				<li className="flex items-center gap-2 text-sm" key={feature}>
					{icon}
					{feature}
				</li>
			))}
		</ul>
	);
};
