import React, { useState } from "react";
import { Text, theme } from "@withjuly/solis";

import { trpc } from "../Utility/trpc";
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardCvcElementOptions } from "@stripe/stripe-js";
import posthog from "posthog-js";
import { FormProvider } from "react-hook-form";
import { useZodForm } from "~/utils/hooks/zod-form";
import { z } from "zod";
import { TextInput } from "../Input/TextInput";
import { useCreator } from "~/utils/context/creator";

interface PaymentFormProps {
	onSuccess?: () => void;
	setIsLoading?: (isLoading: boolean) => void;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
	setIsLoading,
	onSuccess,
}) => {
	const [processingError, setProcessingError] = useState("");

	const stripe = useStripe();
	const elements = useElements();

	const utils = trpc.useContext();
	const createSession = trpc.billing.createSession.useMutation();
	const { creatorProfile } = useCreator();

	const form = useZodForm({
		schema: z.object({
			name: z.string(),
		}),
		values: {
			name:
				creatorProfile?.user?.firstName + " " + creatorProfile?.user?.lastName,
		},
		submit: async (data) => {
			setIsLoading?.(true);

			try {
				const { clientSecret } = await createSession.mutateAsync();

				if (!stripe || !elements || !clientSecret) return;
				const card = elements?.getElement(CardNumberElement);
				if (!card) return;

				const { error } = await stripe.confirmCardSetup(clientSecret, {
					payment_method: {
						card,
						billing_details: {
							name: data.name,
						},
					},
				});

				if (error) {
					setProcessingError(error?.message ?? "");
				} else {
					// Give a sec for payment information to propagate, then invalidate
					setTimeout(() => {
						utils.billing.invalidate();
					}, 1000);

					posthog.capture("Add Payment Method");

					onSuccess?.();
				}
			} catch (_) {
				// Do nothing
			}

			setIsLoading?.(false);
		},
	});

	const cardStyle: StripeCardCvcElementOptions = {
		style: {
			base: {
				color: "#FFFFFF",
				fontSize: "14px",
				"::placeholder": {
					color: theme.color.gray[200],
				},
				iconColor: "#FFFFFF",
			},
			invalid: {
				color: theme.color.danger[500],
				iconColor: theme.color.danger[500],
			},
		},
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.onSubmit} id="payment-form">
				<div className="flex flex-col gap-6">
					<TextInput
						name="name"
						label="Name on card"
						placeholder="Name on card"
					/>
					<div className="flex flex-col gap-2">
						<Text variant="body/sm">Card information</Text>
						<div className="rounded-lg border border-gray-200 bg-gray-600">
							<div className="border-gray-500 p-3">
								<CardNumberElement options={cardStyle} />
							</div>
							<div className="flex rounded-b-lg border-t">
								<div className="w-full border-r p-3">
									<CardExpiryElement options={cardStyle} />
								</div>
								<div className="w-full p-3">
									<CardCvcElement options={cardStyle} />
								</div>
							</div>
						</div>

						{processingError && (
							<Text variant="body/xs" className="text-danger-500 mt-1">
								{processingError}
							</Text>
						)}
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
